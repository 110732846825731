/**
 * Constants for the analytics event types.
 * These are web-exclusive.
 */
export abstract class AnalyticsEventType {
  /**
   * Type for when an user has interacted in a way with the entity (i.e. clicking, entering, etc.)
   */
  static readonly INTERACTION = "interaction";
  static readonly STARTED = "started";
  static readonly REDIRECT_TO = "redirectTo";
  static readonly LINK_TO = "linkTo";
}
