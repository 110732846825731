/**
 * Constants for the interaction event types. Will be sent during the
 * analytics call.
 *
 * @see {@link AnalyticsEventType}
 */
export abstract class AnalyticsInteractionEvent {
  /**
   * Type for when an user has clicked with a mouse click.
   */
  static readonly CLICK_EVENT = "click";
  /**
   * Interaction via the keyboard event
   */
  static readonly KEYBOARD_EVENT = "keypress";
  /**
   * Unspecified interaction event.
   */
  static readonly NONE = "none";
}
