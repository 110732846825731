/**
 * Constants for the redirect target.
 */
export abstract class AnalyticsRedirectTarget {
  /**
   * When the user is redirected to the forgot password page
   */
  static readonly FORGOT_PASSWORD = "forgotPassword";

  /**
   * When the user is redirected to the helpdesk
   */
  static readonly HELPDESK = "helpdesk";

  /**
   * When the user is redirected to documents
   */
  static readonly DOCUMENTS = "documents";
}
